<template>
    <div>
        <div class="screen_filter"></div>
        <!-- 상품 팝업 시작 -->
        <div class="popup popup_proposal">
            <button type="button" @click="closePopup">
                <img src="@/assets/images/common/icon_close_w.svg" alt="close_w" />
            </button>

            <div class="inner">
                <div class="product_title">
                    <h1>{{ this.matchingData.matching_name }}</h1>
                </div>
                <div class="user">
                    <span>판매자</span>
                    <p>{{ this.matchingData.name }}</p>
                </div>

                <div class="product">
                    <div class="ask">
                        <div class="ask_icon">
                            <img src="@/assets/images/common/icon_token_w.svg" alt="token" />
                        </div>
                        <h1>제안하기</h1>
                        <p>토큰을 사용하여 해당 매칭에 제안하시겠습니까?</p>
                    </div>

                    <div class="scale_wrap">
                        <div class="scale scale_ticket">
                            <p>최소 제안 토큰</p>
                            <h1>{{ this.numberFormat(this.matchingData.min_token) }}<span>개</span></h1>
                        </div>
                        <div class="scale scale_ticket">
                            <p>추가할 토큰</p>
                            <div class="add_ticket">
                                <button class="minus" @click="changeMetaToken('-', $event)">-</button>
                                <input type="text" :value="this.meta_token" @input="changeMetaToken('=', $event)" />
                                <button class="plus" @click="changeMetaToken('+', $event)">+</button>
                            </div>
                        </div>
                        <div class="scale scale_ticket">
                            <p>보유 토큰</p>
                            <h1>{{ this.numberFormat(this.user.meta_token) }}<span>개</span></h1>
                            <button class="sub_button" @click="$router.push('/mypage/MypageBuyToken')">
                                토큰 충전
                            </button>
                        </div>
                        <div class="btn_wrap">
                            <button class="sub_button" @click="$router.push('/mypage/MypageBuyToken')">
                                <p>토큰 충전</p>
                            </button>
                        </div>
                    </div>
                </div>

                <div class="last_btn_wrap">
                    <button
                        id="matchingRequest"
                        class="common_button"
                        @click="requestMatching"
                        disabled
                    >
                        <p>확인</p>
                    </button>

                    <button class="sub_button" @click="closePopup">
                        <p>취소</p>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

import { postData } from "@/utils/postData";
import { numberFormat } from "@/utils/numberFormat";
import { changeMetaToken } from "@/utils/changeMetaToken";

export default {
    mixins: [postData, numberFormat, changeMetaToken],
    computed: {
        ...mapState("user", ["user"]),
        ...mapState("access_token", ["access_token"]),
    },
    props: {
        which: String,
        matchingData: Object,
    },
    watch: {
        meta_token(val) {
            if (val < this.matchingData.min_token) {
                document.getElementById("matchingRequest").disabled = true;
            } else {
                document.getElementById("matchingRequest").disabled = false;
            }
        },
    },
    data() {
        return {
            meta_token: 0,
        };
    },
    methods: {
        // 팝업 닫기
        closePopup() {
            this.$emit("closePopup");
        },
        // 제안하기
        requestMatching() {
            if (this.meta_token == 0 || this.meta_token < this.matchingData.min_token) {
                this.$store.commit("alert", "최소 제안 토큰 이상의 토큰을 입력해주세요");
                return;
            }
            this.postData(
                "/requestProviderMatching",
                {
                    meta_token: this.meta_token,
                    matching_pk: this.matchingData.matching_pk,
                },
                () => {
                    this.$store.commit( "alert", "제안이 완료되었습니다. <br/><span class='tiny'>거래가 성사되지 않으면 토큰은 전액 반환됩니다.</span>");
                    this.$store.commit("user/metaTokenUse", this.meta_token);
                    this.closePopup();
                },
            );
        },
    },
};
</script>

<style scoped src="@/assets/css/components/commonPopup.css"></style>