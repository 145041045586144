<template>
  <div class="content_container product_detail">
    <div class="inner">
      <div class="left_con">
        <h1>{{ this.matchingData.matching_name }}</h1>

        <div class="image_wrap">
          <span v-if="this.matchingData.matching_image.length == 0"
            >상품 이미지가 없습니다.</span
          >
          <template v-else>
            <div class="main_image">
              <img
                :src="this.main_image.path + this.main_image.save_name"
                :alt="this.main_image.original_name"
              />
            </div>
            <div class="image_list">
              <div
                v-for="(item, i) in this.matchingData.matching_image"
                :key="i"
                @click="switchMainImage(i)"
              >
                <img
                  :src="item.path + item.save_name"
                  :alt="item.original_name"
                />
              </div>
            </div>
          </template>
        </div>

        <div class="table_wrap">
          <table>
            <tr class="top">
              <th>카테고리</th>
              <td>{{ this.matchingData.category }}</td>
              <th>매칭 마감일</th>
              <td v-if="this.matchingData.finish_date">{{ this.dateFormat(this.matchingData.finish_date) }}</td>
              <td v-else>-</td>
            </tr>
            <tr class="top">
              <th>요청수량</th>
              <td>{{ this.matchingData.moq }}</td>
              <th>판매가능시점</th>
              <td>{{ this.matchingData.start }}</td>
            </tr>
            <tr class="top">
              <th>상품단가</th>
              <td>{{ this.matchingData.price }}</td>
              <th>예상마진</th>
              <td>{{ this.matchingData.margin }}</td>
            </tr>
            <tr class="top">
              <th>선호 공급자 경력</th>
              <td>{{ this.matchingData.career }}</td>
            </tr>
            
            <!--공급 상품 특징 넣기-->
            <tr class="ways con">
              <th>선호 상품 조건</th>
              <td colspan="3">
                <div class="wrap">
                  <div
                    class="way"
                    v-for="(item, i) in this.matchingData.condi"
                    :key="i"
                  >
                    {{ item.condi }}
                  </div>
                </div>
              </td>
            </tr>

            <tr class="explain con">
              <th>상품 URL</th>
              <td v-if=" this.matchingData.url" colspan="3">{{ this.matchingData.url }}</td>
              <td v-else colspan="3">-</td>
            </tr>

            <tr class="explain con">
              <th>상품설명</th>
              <td colspan="3">{{ this.matchingData.explanation }}</td>
            </tr>
          </table>
        </div>
      </div>

      <!--right_fixed 시작-->
      <div class="right_fixed">
        <div class="right_wrap">
          <div class="top">
            <span class="label_seller">
              {{ this.matchingData.user_division }}
            </span>
            <router-link :to="`/seller/detail/${this.matchingData.id}`"
              ><p class="text_link">프로필 보기</p></router-link
            >
          </div>
          <div class="profile">
            <img
              v-if="this.matchingData.original_name"
              :src="this.matchingData.path + this.matchingData.save_name"
              :alt="this.matchingData.original_name"
            />
            <img
              v-else
              src="@/assets/images/common/charactor_01.png"
              alt="프로필사진"
            />
          </div>
          <div class="user_class" :class="this.matchingData.level_icon">
            <p>{{ this.matchingData.level }}</p>
          </div>
          <h1>{{ this.matchingData.name }}</h1>
        </div>
        <button
          v-if="this.user.user_division == '공급자'"
          @click="openPopup"
          :disabled="this.matchingData.state != '매칭중'"
          class="common_button">
          {{this.matchingData.state == '매칭중' ? '제안하기' : '마감'}}
        </button>

        <!--btn_left 누르면 옆에서 팝업 나타나기-->
        <div class="btn_left" @click="openRightFixed">
          <span class="gt">&lt;</span>
        </div>
      </div>
      <!--right_fixed 끝-->
    </div>
  </div>

  <ProviderToMatching
    v-if="showPopup"
    :matchingData="matchingData"
    @closePopup="closePopup"
  />
</template>

<script>
import ProviderToMatching from '@/components/Proposal/ProviderToMatching'
import { fetchData } from "@/utils/fetchData";
import { numberFormat } from "@/utils/numberFormat";
import { dateFormat } from "@/utils/dateFormat";
import { mapState } from 'vuex';

export default {
  components : {
    ProviderToMatching
  },
  props: {
    id: String,
  },
  computed : {
    ...mapState('user', ['user']),
  },
  mounted() {
    this.fetchMatchingData();
  },
  data() {
    return {
      main_image: {},

      matchingData: {
        category: [],
        condi: [],
        matching_image: [],

        // 등등
      },

      showPopup : false,
    };
  },
  mixins: [ fetchData, numberFormat, dateFormat ],
  methods: {
    // 상품 데이터 불러오기
    fetchMatchingData() {
      this.fetchData(`/getMatchingDetail/${this.id}`, (data) => {
        this.matchingData = data.data;
        this.switchMainImage(0);
      });
    },
    switchMainImage(i) {
      this.main_image = this.matchingData.matching_image[i];
    },
    openRightFixed(event){
      event.currentTarget.classList.toggle('open');
      const right_fixed = document.querySelector('.right_fixed');
      right_fixed.classList.toggle('open');
    },
    openPopup(){
      if(this.matchingData.state == '매칭중'){
        this.showPopup = true;
      }
    },
    closePopup(){
      this.showPopup = false;
    }
  },
};
</script>

<style scoped src="@/assets/css/layout_product.css">
</style>